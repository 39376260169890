"use client";
import React from "react";
import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });
import groovyWalkAnimation from "../../public/animation-404.json";

function error({ error, reset }: { error: Error; reset: () => void }) {
    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="w-screen h-screen flex flex-col items-center">
                {" "}
                <Lottie animationData={groovyWalkAnimation} loop={true} className="w-2/3 h-3/4" />
                <button
                    onClick={() => {
                        reset();
                    }}
                    className="font-medium border rounded-lg pr-4 pl-4 pt-3 pb-3 bg-red-400 text-white"
                >
                    Thử lại
                </button>
            </div>
        </div>
    );
}

export default error;
